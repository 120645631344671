<template>
    <div class="home_page_wrapper">
        <div class="coupon_wrapper" v-show="blackFridayBanner">
            <div class="coupon">
                <div class="close_button" @click="closeBlackFridayBanner()">
                    <i class="uil uil-multiply"></i>
                </div>
                <div class="coupon_text_wrapper">
                    <div class="name_and_discount">
                        <span>Happy Women's Day</span>
                        <span>-20%</span>
                    </div>
                    <p>The coupon is active till 8th March. We wish you a Happy Women's Day!</p>
                </div>
            </div>
        </div>
        <div class="main_photo_wrapper">
            <!-- <div class="main_title_wrapper">
                <h5>AMURA</h5><br>
                <h5>{{ $t('about.main_title_wrapper_text') }}</h5>
            </div> -->
            <div class="main_photo">
                <router-link to="/shop"><button><span>shop now</span></button></router-link>
            </div>
        </div>
        <div class="welcome_text_paragraf">
            <div class="text_about_wrap">
                <p v-for="(thisPar, thisParIdx) in welcome_text_paragrafArr" :key="thisParIdx">{{ thisPar }}</p>
                <router-link to="/about"><button><span>Learn more</span></button></router-link>
            </div>
            <div class="journal_photo">
                <img src="@/assets/homepage2.jpg">
            </div>
        </div>
        <div class="info_section">
            <div class="section_wrapper">
                <h5>{{ $t('about.info_section[0]') }}</h5>
                <span>{{ $t('about.info_section[1]') }}</span>
                <span>{{ $t('about.info_section[2]') }}</span>
                <!-- <router-link to="/about">
                    <div class="action_button">
                        <span>{{ $t('our_history') }}</span>
                    </div>
                </router-link> -->
            </div>
        </div>
        <div class="caviar_select_wrapper">
            <div class="titele_text_wrapper">
                <h5>{{ $t('our_mission').toUpperCase() }}</h5>
                <h5>{{ $t('about.info_section[1]') }}</h5>
            </div>
            <div class="caviar_photo_wrapper">
                <div class="media_scroller noScrollBar snap_inline">
                    <div class="caviar_image1 snap-start" @click="() => $router.push('/product/1')"></div>
                    <div class="caviar_image3 snap-center" @click="() => $router.push('/product/2')"></div>
                    <div class="caviar_image2 snap-center" @click="() => $router.push('/product/3')"></div>
                    <div class="caviar_image4 snap-end" @click="() => $router.push('/product/4')"></div>
                    <div class="caviar_image5 snap-end" @click="() => $router.push('/product/5')"></div>
                </div>
            </div>
            <router-link to="/shop">
                <div class="action_button">
                    <span>{{ $t('caviar') }}</span>
                </div>
            </router-link>
        </div>
        <div class="delivery_info_block_wrapper">
            <div class="text_icon_wrapper">
                <i class="uit uit-shield-check"></i>
                <span class="special_text">{{ $t('secure_payment') }}</span>
            </div>
            <div class="text_icon_wrapper">
                <i class="uit uit-truck"></i>
                <span class="special_text">
                    {{ $t('delivery_promo_text') }}
                </span>
            </div>
            <div class="text_icon_wrapper_borders">
                <i class="uit uit-box"></i>
                <span class="special_text">{{ $t('refrigerated_packaging') }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t, te } = useI18n()

const welcome_text_paragrafArr = computed(() => {
    const arr = []
    let i = 0
    while (te('about.welcome_text_paragraf[' + i + ']')) {
        arr.push(t('about.welcome_text_paragraf[' + i + ']'))
        i++
    }

    return arr
})

// black-friday-discount START
// const blackFridayBanner = ref(true)
// if (localStorage.getItem('blackFriday3BannerClosed')) blackFridayBanner.value = false
// function closeBlackFridayBanner() {
//     blackFridayBanner.value = false
//     localStorage.setItem('blackFriday3BannerClosed', 'true')
// }
// black-friday-discount END
</script>

<style>
    @import url('@/styles/css/homePage.css');
</style>