<template>
    <div class="shopping_cart" :class="{'closed': !opened}" ref="shoppingCartEl" v-show="!hidden">
        <div class="bag_quantity">
            <div class="quantity-wrapper">
                <div class="quantity" v-if="shoppingCartElCount > 0"> 
                    <span>your bag</span>
                    <span>(&nbsp;{{ shoppingCartElCount }}&nbsp;)</span>
                </div>
                <span v-else>Your bag is empty</span>
            </div>
            <div @click="$emit('toggleState', false)">
                <i class="uil uil-multiply"></i>
            </div>
        </div>

        <div class="items_scroll_y noScrollBar">
            <cart-item v-for="(thisProd, thisProdIdx) in $store.state.myCart.cartData" :key="`${thisProd.product_id}_${thisProdIdx}`"
            :thisProd="thisProd"></cart-item>
        </div>

        <div class="subtotal_wrapper">
            <div class="fixed_position">
                <div class="subtotal_price">
                    <small>subtotal</small>
                    <span>{{ shoppingCartSubtotal }}€</span>
                </div>
                <div class="coupon_wrapper" v-if="blackFridayBanner">
                    <div class="coupon">
                        <div class="side_left">
                            <div class="coupon_text_wrapper">
                                <span>Happy Women's Day</span>
                                <p>The coupon is active till 8th March. We wish you a Happy Women's Day!</p>
                            </div>
                        </div>
                        <div class="side_right">
                            <div class="dash_line"></div>
                            <div class="discont_amount_wrapper">
                                <span>-20%</span>
                                <small>ACTIVE</small>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="order_button" @click="placeOrder()" v-if="shoppingCartElCount > 0">
                    <span>place the order</span>
                </button>
            </div>
        </div>
    </div> 
</template>

<script>
import cartItemVue from '@/components/cartItem.vue'

export default {
    components: {
        cartItem: cartItemVue
    },
    props: {
        opened: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            hidden: false,
            blackFridayBanner: false // black-friday-discount | false = no discount
        }
    },
    methods: {
        placeOrder() {
            this.$router.push('/checkout')
            this.$emit('toggleState', false)
        }
    },
    computed: {
        shoppingCartElCount() {
            let productCount = 0
            this.$store.state.myCart.cartData.forEach(thisItem => {
                productCount += thisItem.product_quantity
            })
            return productCount
        },
        shoppingCartSubtotal() {
            let subtotal = 0
            this.$store.state.myCart.cartData.forEach(thisItem => {
                subtotal += thisItem.product_price * thisItem.product_quantity
            })

            if (this.blackFridayBanner) {
                subtotal = Math.round((subtotal - (subtotal * 0.2)) * 100) / 100; // black-friday-discount (No need to comment this line)
            }

            return subtotal.toFixed(2)
        }
    }
}
</script>

<style>
    @import url('@/styles/css/shoppingCart.css');
</style>